import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "./features/modal/modalSlice";

export const store = configureStore({
	reducer: {
		modalHandler: modalReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
