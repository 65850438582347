import React from "react";
import { BeatLoader } from "react-spinners";
import styles from "./mainLoading.module.css";

export default function MainLoading({ loading, size = 20 }) {
	const cssOverride = {
		display: "block",
		margin: "0 auto",
		borderColor: "red",
	};

	return (
		loading && (
			<div className={styles.container} id="loadingModal">
				<BeatLoader color={"#ffffff"} loading={loading} cssOverride={cssOverride} size={size} />
			</div>
		)
	);
}
