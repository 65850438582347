import React from "react";
import { Navigate } from "react-router-dom";
import getPermissions from "../../getPermissions";
import logout from "../../logout";
import jwt_decode from "jwt-decode";
import ReactSession from "../../reactSession";

function PrivateRoute({ element, permission }) {
	// Check if the user has the required permissions
	const permissions = getPermissions();
	const hasPermissions = permissions?.includes(permission);
	// Check if token is expired
	try {
		const token = ReactSession.get("token");
		const decodedToken = jwt_decode(token);
		if (decodedToken.exp < Date.now() / 1000) {
			logout();
			return <Navigate to="/login" />;
		}
		// If the user has the required permissions, return the element
		if (hasPermissions) {
			return element;
		}
		return <Navigate to="/404" />;
	} catch {
		return <Navigate to="/login" />;
	}
}

export default PrivateRoute;
