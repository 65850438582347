import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "../locales/en";
import pt from "../locales/pt";
import es from "../locales/es";
import fr from "../locales/fr";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: "en",
		saveMissing: true,
		interpolation: {
			escapeValue: false,
		},
		// LANGUAGES
		resources: {
			en,
			pt,
			es,
			fr,
		},
	});

export default i18n;
