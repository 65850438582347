import { logoutReq } from "./requests/delete/logout";
import reactSession from "./reactSession";

function logout() {
	reactSession.setStoreType("localStorage");
	Promise.all([logoutReq()]).then(() => {
		localStorage.removeItem("__react_session__");
	});
}

export default logout;
