const apiDomain = process.env.REACT_APP_API_DOMAIN;

const requestOptions = (method, token) => ({
	method: method,
	headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
});

// ALL Contracts
export async function logoutReq() {
	let obj = "";
	const token = JSON.parse(localStorage.getItem("__react_session__")).token;

	await fetch(apiDomain + "/logout", requestOptions("DELETE", token))
		.then((response) => response.json())
		.then((data) => (obj = data))
		.catch((serverError) => console.error("Server error: Could not logout."));
	return obj;
}
