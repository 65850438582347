import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
	name: "modalHandler",
	initialState: {
		isModalOpen: false,
		modalTitle: "Modal Title",
		modalContent: "Modal Content",
		saveBtnExists: true,
		saveBtnName: null,
		onSaveBtnFunction: () => {},
		onCloseBtnFunction: null,
		onCloseNavigateTo: null,
		reloadPageOnClose: false,
		reloadPageOnSave: false,
		extraBtns: null,
	},
	reducers: {
		openModal: (state) => {
			state.isModalOpen = true;
		},
		closeModal: (state) => {
			state.isModalOpen = false;
		},
		addSaveBtn: (state) => {
			state.saveBtnExists = true;
		},
		removeSaveBtn: (state) => {
			state.saveBtnExists = false;
		},
		setModalTitle: (state, action) => {
			state.modalTitle = action.payload;
		},
		setModalContent: (state, action) => {
			state.modalContent = action.payload;
		},
		setSaveBtnFunction: (state, action) => {
			state.onSaveBtnFunction = action.payload;
		},
		setSaveBtnName: (state, action) => {
			state.saveBtnName = action.payload;
		},
		setCloseBtnFunction: (state, action) => {
			state.onCloseBtnFunction = action.payload;
		},
		onCloseNavigateTo: (state, action) => {
			state.onCloseNavigateTo = action.payload;
		},
		setReloadPageOnClose: (state, action) => {
			state.reloadPageOnClose = action.payload;
		},
		setReloadPageOnSave: (state, action) => {
			state.reloadPageOnSave = action.payload;
		},
		setExtraBtns: (state, action) => {
			state.extraBtns = action.payload;
		},
		removeExtraBtns: (state, action) => {
			state.extraBtns = null;
		},
	},
});

export const {
	openModal,
	closeModal,
	setModalTitle,
	setModalContent,
	setCloseBtnFunction,
	onCloseNavigateTo,
	setSaveBtnFunction,
	addSaveBtn,
	setSaveBtnName,
	removeSaveBtn,
	setReloadPageOnClose,
	setReloadPageOnSave,
	setExtraBtns,
	removeExtraBtns,
} = modalSlice.actions;

export default modalSlice.reducer;
