import React, { lazy, Suspense, memo } from "react";
import { Outlet } from "react-router-dom";
import { Layout as LayoutAntd } from "antd";
import { useMediaQuery } from "react-responsive";
import MainLoading from "../../components/loadings/mainLoading/mainLoading";
import getPermissions from "../../utils/getPermissions";

import style from "./layout.module.css";

const Header = lazy(() => import("../headers/websiteHeader/header"));
const Sidebar = lazy(() => import("../../assemblies/sidebar/sidebar"));

const Layout = memo(() => {
	const { Header: HeaderAntd, Content } = LayoutAntd;
	const isMobile = useMediaQuery({ maxWidth: 540 });
	const isTablet = useMediaQuery({ maxWidth: 900 });
	const permissionsArray = getPermissions();

	const contentStyle =
		permissionsArray && permissionsArray.includes("IOT_Dashboard_Home_View")
			? {
					padding: isMobile ? 20 : 30,
					paddingLeft: isMobile ? 20 : isTablet ? 110 : 230,
					background: "#37404a",
			  }
			: {
					padding: isMobile ? 20 : 30,
					background: "#37404a",
			  };

	return (
		<LayoutAntd style={{ minHeight: "100vh", height: "100%" }}>
			<HeaderAntd className={style.header}>
				<Header />
			</HeaderAntd>
			<LayoutAntd hasSider>
				{permissionsArray && permissionsArray.includes("IOT_Dashboard_Home_View") && <Sidebar />}
				<Content style={contentStyle}>
					<Suspense fallback={<MainLoading loading={true} />}>
						<Outlet />
					</Suspense>
				</Content>
			</LayoutAntd>
		</LayoutAntd>
	);
});

export default Layout;
