// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainLoading_container__gYp55 {\r\n\tposition: fixed;\r\n\ttop: 50%;\r\n\tleft: 50%;\r\n\ttransform: translate(-50%, -50%);\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\tbackground-color: rgba(0, 0, 0, 0.3);\r\n\theight: 100%;\r\n\twidth: 100%;\r\n\tz-index: 30000;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/loadings/mainLoading/mainLoading.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,QAAQ;CACR,SAAS;CACT,gCAAgC;CAChC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,oCAAoC;CACpC,YAAY;CACZ,WAAW;CACX,cAAc;AACf","sourcesContent":[".container {\r\n\tposition: fixed;\r\n\ttop: 50%;\r\n\tleft: 50%;\r\n\ttransform: translate(-50%, -50%);\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\tbackground-color: rgba(0, 0, 0, 0.3);\r\n\theight: 100%;\r\n\twidth: 100%;\r\n\tz-index: 30000;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "mainLoading_container__gYp55"
};
export default ___CSS_LOADER_EXPORT___;
